import React from 'react';
// dependencies
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ProtectedRoute from './layout/ProtectedRoute';
import "./App.css"
//pages
import LoginForm from './views/Login';
import ArticleHistory from './views/Home/ArticleHistory';


function App() {
  const router = createBrowserRouter([
    {
      path: '/home',
      element: (<ProtectedRoute>
        <ArticleHistory />
      </ProtectedRoute>),
    },
    {
      path: '/login',
      element: <LoginForm />,
    },
    {
      path: '*',
      element: (<ProtectedRoute>
        <ArticleHistory />
      </ProtectedRoute>),
    },
  ]);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
