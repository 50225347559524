import React from 'react'
import { Layout, Button, Tooltip } from 'antd';
import AIMLogo from "../assets/AIM-logo-black-1536x668.png"
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;
const headerStyle = {
    color: '#fff',
    height: 64,
    backgroundColor: '#FBE509',
    maxWidth: "1440px",
    margin: "0 auto"
};


const HeaderLayout = ({ children }) => {
    const navigate = useNavigate();
    return (
        <>

            <Header style={headerStyle}>
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <img
                        src={AIMLogo}
                        alt="surprize"
                        className="img-fluid"
                        loading="lazy"
                        style={{ width: "100px", height: "50px" }}
                    />
                    <Tooltip title="Logout">
                        <Button onClick={() => {
                            sessionStorage.removeItem('auth')
                            navigate("/login")
                        }} type="primary" shape="circle" icon={<LogoutOutlined />} style={{ backgroundColor: "#000" }} />
                    </Tooltip>
                </div>
            </Header>
            <div style={{ padding: "30px" }}>

                {children}
            </div>
        </>
    )
}

export default HeaderLayout