// ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import HeaderLayout from './Header';

const ProtectedRoute = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(JSON.parse(localStorage.getItem("auth"))?.token);

    useEffect(() => {
        setIsLoggedIn(JSON.parse(localStorage.getItem("auth"))?.token)
    }, [])


    if (!isLoggedIn) {
        // Redirect to login if not authenticated
        return <Navigate to="/login" />;
    }

    return (
        <HeaderLayout>
            {children}
        </HeaderLayout>


    ); // Render the protected component
};

export default ProtectedRoute;